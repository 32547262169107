:root {
  --title-color: #3E566C;
  --gray: #E7E8E9;
  --gray-2: #DEDEDE;
  --gray-3: #566C81;
  --gray-4: #9E9E9E;
  --gray-5: #F7F7F7;
  --gray-6: #BDCDDA;
  --gray-7: #808080;
  --gray-8: #333333;
  --gray-9: #E7E9EB;
  --gray-10: #E4ECF3;
  --gray-11: #D7E2EB;
  --gray-12: #F1F1F1;
  --gray-13: #F6F6F6;
  --gray-14: #EFF8FF;
  --gray-15: #C5D7E6;
  --gray-16: #CED3D8;
  --gray-17: #C1D4E2;
  --gray-18: #DAE5ED;
  --gray-19: #FCFDFE;
  --gray-20: #BCBEC0;
  --gray-21: #C4C4C4;
  --gray-22: #6C6C6C;
  --gray-23: #455968;
  --blue: #1282C3;
  --blue-2: #299bd6;
  --blue-3: #0C5089;
  --blue-4: #D6E3EE;
  --blue-5: #ECF4FB;
  --blue-6: #4381B5;
  --blue-7: #6B8196;
  --blue-8: #DFE8EF;
  --blue-9: #8EA6BA;
  --blue-10: #3B5B79;
  --blue--11: #566C80;
  --blue-12: #1866A7;
  --blue-13: #EDF6FF;
  --blue-14: #DCEFFF;
  --blue-15: #4583BC;
  --orange: #FD603E;
  --purple: #BE66F4;
  --yellow: #FFBB0B;
  --green: #08AB36;
  --green-2: #7BCD54;
  --primary-color: #0C5089;
  --secondary-color: #FD603E;
  --secondary-color-2: #EC4D29;
  --secondary-color-3: #FF9F40;
  --white: #FFFFFF;
  --black: #000000;

  --new-primary-color: #0093B6;
  --v2-green-2: #EEF2F7;
  --green-3: #5CB4C9;
  --green-4: #80c9db;
  --green-5: #0084A4;
  --red: #F26563;

  --v2-blue: #0093B6;
  --v2-blue-darken: #007f9f;
  --v2-blue-2: #7691BB;
  --v2-blue-2-30: #d2daea;
  --v2-blue-2-60: #adbbd6;
  --v2-blue-light: rgba(92, 180, 201, 0.6);
  --v2-blue-light-2: rgba(92, 180, 201, 0.5);
  --v2-blue-bright: #5CB4C9;
  --v2-blue-dark: #2B589F;
  --v2-blue-dark-30: #c0cce2;
  --v2-blue-dark-60: #859dc6;
  --v2-dark: #173042;
  --v2-purple: #9C69A9;
  --v2-purple-30: #ddcfe5;
  --v2-purple-60: #c2a5cb;
  --v2-purple-light: rgba(156, 105, 169, 0.2);
  --v2-purple-dark: #6A1580;
  --v2-purple-dark-50: rgba(106, 21, 128, 0.5);
  --v2-red: #F26563;
  --v2-red-hover: #FA0F0C;
  --v2-red-hover-30: #f8bbbd;
  --v2-red-hover-60: #fa7879;
  --v2-red-2: #F26849;
  --v2-pink-hover: rgba(106, 21, 128, 0.1);
  --v2-yellow: #F2B063;
  --orange-washed-background: #fcefe0;

  --plan-free: #FAFAFA;
  --plan-lite: #E8F4F6;
  --plan-starter: #5CB4C9;
  --plan-professional: #7691BB;
  --plan-growth: #0093B6;
  --plan-mastery: #9C69A9;
}

$lightGrey: #c6c6c6;
$black: #000000;
$grey: #777777;
$violet: #b74cee;
$midBlue: #0081db;
$lightBlue: #00badb;
$aquamarine: #00dba5;
$green: #6ecb00;
$yellow: #f7c429;
$washedRedSwatch: #FD603D;
$red: #ff1919;

$swatchColors: $lightGrey, $black, $grey, $violet, $midBlue, $lightBlue, $aquamarine, $green, $yellow, $washedRedSwatch, $red, $lightGrey;

$blue: #299bd6;
$washedRed: #FD603D;
$gold: #FFD700;
$darkRed: #d65555;
$darkRed: #d65555;
$darkGreen: #299c29;

$bgGrey: #f8f9fd;
$bgDanger: unquote('#ffc6c6d9');
$blackText: $black;
$whiteText: #ffffff;
$greyText: #b9b9b9;
$greyBlueText: #4f5f6f;
$softPlaceholder: $lightGrey;
$darkGreyText: $grey;
$componentGreyText: #919191;
$greyOutline: #e7e8ec;
$disabledOutline: #d4d4d4;
$downdownArrow: #d4d4d4;
$checkboxBorder: #d4d4d4;
$searchFieldBackground: #fcfcfc;
$tableHeadings: $componentGreyText;
$tableSmall: #a7a5a5;
$goodGreen: $green;
$veryLightGrey: #f3f3f3;

$subtitleText: #4e4e4e;
$gold: #ffbe20;

// badge colors
$badgeBlue: #5d78ff;
$badgeGreen: #1dc9b7;
$badgeRed: #fd397a;
$badgeYellow: #df981c;
$badgeGold: $gold;
$badgeGray: #847C7C;
