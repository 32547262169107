@use "sass:math";

html {
  font-family: $Geomanist;
  //font-family: Helvetica, Arial, sans-serif;
}

.page-title {
  font-size: math.div(18em, 16);
  margin-bottom: math.div(25em, 22);
  line-height: 1.4;
}

.text-right {
  text-align: right !important;
}

.text-error {
  margin-bottom: 2em;
  color: $washedRed;
}

.text-green {
  color: $green;
}

.text-red {
  color: $washedRed;
}

.text-yellow {
  color: $yellow;
}

.pointer {
  cursor: pointer;
}
