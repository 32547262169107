@import 'colors';
@import 'fonts';


// Layout

//$sidebarWidth: 250; // px
$sidebarWidth: 80;
$newSidebarWidth: 62; // px
$newExpandedSidebarWidth: 276; // px
$expandedSidebarWidth: 250; // px
$headerHeight: 80; // px
$headerHeightPhone: 60; // px

$introPagePadding: 60; // px
$contentPadding: 40; // px
$centeredContentWidth: 1280 + $contentPadding * 2; // px
$wideCenteredContentWidth: 1400 + $contentPadding * 2; // px
$halfContentWidth: $centeredContentWidth * 0.5;
$secondLevelSelectOffset: 100%; // calc(100% + 3em / 16);

$contentLayerZIndex: 1;

$paragraphMarginBottom: .75em;

$tableRowVerticalPadding: 12; // px
$tableCellHorizontalPadding: 15; // px

$phoneContentPadding: 16; // px

$whiteBoxPadding: 38; // px
$phoneWhiteBoxPadding: 26; // px

$checkboxOverflow: 5; // px


// Break points

	// General
	$phone: 760px;
	$tablet: 1300px;
	$tightDesktop: 1550px;
$mediumDesktop: 1680px;

	// Per page
	$logInBreakPoint: 1150px;
	$phoneShowMore: 900px;
	$tightTablet: 1000px;
	// Scaling values
  $baseScaling: 90%;
	$tightDesktopScaling: .95;
	$phoneScaling: .9;


// Typefaces

$Geomanist: Geomanist, sans-serif;
$GeomanistThin: 100;
$GeomanistExtraLight: 200;
$GeomanistLight: 300;
$GeomanistRegular: 400;
$GeomanistBook: 500;
$GeomanistMedium: 600;
$GeomanistBold: 700;
$GeomanistBlack: 800;
$GeomanistUltraBlack: 900;


// Other

$coolEasing: cubic-bezier(.4, 0, .1, 1);

:root {
  --c6-header-height: 0px;
}
