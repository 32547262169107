@use "sass:math";

@import '../common/variables';

::-webkit-scrollbar
{
    width: math.div(6em, 16);
}

::-webkit-scrollbar:horizontal
{
    height: math.div(6em, 16);
}
 
::-webkit-scrollbar-track
{
    background: transparent;
}
 
::-webkit-scrollbar-thumb
{
	background-color: $washedRed;
}
