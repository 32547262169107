@use "sass:math";

.copyright {
  position: absolute;
  font-size: 0.833rem;
  bottom: 50px;
  left: 0;
  width: 100%;
  z-index: 3;
  color: #E8F4F6;
  text-align: center;

  a {
    display: inline-block;
    margin-left: 5px;
    font-weight: 600;
    &:hover {
      color: var(--v2-purple-dark);
      &:before {
        color: #E8F4F6;
      }
    }
  }
}

.copyright-link {
  &:before {
    padding-right: 5px;
    content: '|';
    font-weight: 400;
  }
}

.logo-full {
  position: absolute;
  left: 50%;
  top: 280px;
  width: 330px;
  z-index: 3;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 600;
  font-size: 33px;

  span {
    font: var(--font-gilroy)!important;
    color: var(--white)!important;
  }
}

.st-logo {
  background-image: url('/assets/img/c6-sign-up/st-logo.svg');
  background-size: auto;
  background-repeat: no-repeat;
  width: 52px;
  height: 52px;
}

.st-logo-description {
  position: relative;
  text-align: center;
  padding: 0 70px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--white);
}

.logo-description {
  position: absolute;
  top: 348px;
  left: 50%;
  width: 330px;
  transform: translateX(-50%);
  font-family: 'Manrope', sans-serif;
  font-size: 1.11rem;
  font-weight: 600;
  line-height: 1.52rem;
  color: #FFF;
}

.full-width-separator {
  display: block;
  height: math.div(1em, 16);
  background: $greyOutline;
  margin: 0 math.div(-$contentPadding * 1em, 16);
}

.search-box {
  display: block;
  position: relative;
  width: math.div(250em, 14);
  flex-grow: 0;
  flex-shrink: 1;

  input[name="query"] {
    display: block;
    width: 100%;
    padding-right: math.div(50em, 14);
  }

  input[name="query"] {
    display: block;
    width: 100%;
    padding-right: math.div(50em, 14);
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: math.div(3em, 16) math.div(12em, 16) 0;
    display: block;
  }
  button.button-left {
    left: 0;
    padding: math.div(3em, 16) 0.4em 0;
    z-index: 10;
    + input {
      padding-left: 2em;
    }
  }
}

.toolbar.side-to-side {
  margin-left: math.div(-$contentPadding * 1em, 16);
  margin-right: math.div(-$contentPadding * 1em, 16);
  border: math.div(1em, 16) solid $greyOutline;
  border-left: none;
  border-right: none;
  padding: math.div(14em, 16) math.div($contentPadding * 1em, 16);
  display: flex;
  height: math.div(40em + 15em * 2, 16);
  width: calc(100% + #{math.div($contentPadding * 1em, 16) * 2});
  align-items: center;
}

.textual-badge {
  font-size: 0.8em;
  background-color: black;
  padding: 0.25em;
  color: white;
  border-radius: 0.2em;
  cursor: help;
  opacity: 0.8;
  font-family: Verdana, Arial, $Geomanist;
  text-align: center;
  margin-bottom: 0.2em;
  min-width: 1.4em;

  st-icon {
    font-size: 0.9em;
  }
}

.best-seller-badge {
  @extend .textual-badge;
  background-color: #e67a00;
}

.has-coupon-badge {
  @extend .textual-badge;
  background-color: #007600;
}

.product-inactive-badge {
  @extend .textual-badge;
  background-color: #de8a35;
}

.colored-button {
  border: none;
  color: white;
}

button {
  &.blue-button {
    @extend .colored-button;
    background-color: $blue;
  }
  &.red-button {
    @extend .colored-button;
    background-color: $darkRed;
  }
}

.popper--wrapper {
  font-size: .875rem;
  font-family: var(--font-manrope);
  ::ng-deep b {
    font-weight: 500;
  }
}
