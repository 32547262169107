@import "../common/colors";

$badgeColors: (
  'red': $badgeRed,
  'green': $badgeGreen,
  'blue': $badgeBlue,
  'yellow': $badgeYellow,
  'gray': $badgeGray,
  'gold': $badgeGold,
);

@mixin badge-variant($bg) {
  background-color: transparentize($bg, 0.9);
  color: $bg;
}

span.badge {
  font-size: 0.8em;
  color: $componentGreyText;
  white-space: nowrap;

  @each $color, $value in $badgeColors {
    &.badge-#{$color} {
      content: $color;
      @include badge-variant($value);
    }
  }

  &.badge-padding {
    padding: 0.5em 1em;
  }

  &.badge-upper {
    text-transform: uppercase !important;
  }
}
