.break-point
{
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	visibility: hidden;

	@media (max-width: $tablet) { &.tablet { display: block; } }
	@media (max-width: $phone) { &.phone { display: block; } }
}