@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
  url('/assets/type/gilroy/Gilroy-Regular.woff2') format('woff2'),
  url('/assets/type/gilroy/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root {
  --font-gilroy: 'Gilroy', sans-serif;
}
