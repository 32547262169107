@use "sass:math";

@import "variables";

@mixin triangleDown($width, $height, $color) {
  border-top: $height solid $color;
  border-left: $width * 0.5 solid transparent;
  border-right: $width * 0.5 solid transparent;
}

@mixin triangleUp($width, $height, $color) {
  border-bottom: $height solid $color;
  border-left: $width * 0.5 solid transparent;
  border-right: $width * 0.5 solid transparent;
}

@mixin triangleLeft($width, $height, $color) {
  border-right: $width solid $color;
  border-top: $height * 0.5 solid transparent;
  border-bottom: $height * 0.5 solid transparent;
}

@mixin breakDownSplitLayout {
  position: relative;

  .left,
  .right {
    position: relative;
  }
}

@mixin buttonRedHover {
  &:not(.no-hover):hover,
  &:focus {
    background: $washedRed !important;
    color: $whiteText !important;
  }
}

@mixin triangleTop($width, $height, $color) {
  border-left: $width solid transparent;
  border-right: $width solid transparent;
  border-bottom: $height * 0.5 solid $color;
}

@mixin triangleRight($width, $height, $color) {
  border-left: $width solid $color;
  border-top: $height * 0.5 solid transparent;
  border-bottom: $height * 0.5 solid transparent;
}

@mixin triangleUpLeft($width, $height, $color) {
  border-top: $height * 0.5 solid $color;
  border-left: $width * 0.5 solid $color;
  border-right: $width * 0.5 solid transparent;
  border-bottom: $height * 0.5 solid transparent;
}

@mixin burger($width, $height, $thickness, $color) {
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $width;
    margin-left: -$width * 0.5;
    height: $height;
    margin-top: -$height * 0.5;
    border-top: $thickness solid $color;
    border-bottom: $thickness solid $color;
  }

  &::after {
    margin-top: -$thickness * 0.5;
    border-bottom: none;
  }
}

@mixin whitePaneShadow {
  box-shadow: 0 math.div(2em, 16) math.div(6em, 16) rgba(0, 0, 0, .06);
}

@mixin whitePaneShadowWithBorder {
  @include whitePaneShadow;
  border: math.div(2em, 17) solid $greyOutline;
}

@mixin whitePaneShadowDark {
  box-shadow: 0 math.div(3em, 16) math.div(8em, 16) rgba(0, 0, 0, .08);
}

@mixin mobile-mode-box-table {
  &, tbody, tr {
    display: block;
  }

  thead {
    display: none;
  }

  tr {
    padding: math.div($whiteBoxPadding * 1em, 16);
    padding-bottom: math.div($whiteBoxPadding * 1em, 16) - 1.5em;
    border-top: 0;

    @media (max-width: $phone) {
      padding: math.div($phoneWhiteBoxPadding * 1em, 16);
    }

    &:hover {
      background: none;
    }
  }

  td {
    display: inline-block;
    padding-left: 0;
    padding-top: 0;
    padding-right: 2em;
    padding-bottom: 1.5em;
    margin-right: -.5em;

    &::before {
      content: attr(data-field);
      display: block;
      font-size: .7em;
      text-transform: uppercase;
      opacity: .7;
      margin-bottom: .5em;
    }
  }
}

@mixin prefix ( $property, $value ) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
  #{$property}: $value;
}

@mixin transition( $value ) {
  -webkit-transition: $value !important; // important to override bootstrap
  -moz-transition: $value !important;
  -ms-transition: $value !important;
  -o-transition: $value !important;
  transition: $value !important;
}

@mixin transform( $value ) {
  @include prefix( transform, $value );
}

@mixin userSelect( $value ){
  -webkit-touch-callout: $value; /* iOS Safari */
  -webkit-user-select: $value; /* Safari */
  -khtml-user-select: $value; /* Konqueror HTML */
  -moz-user-select: $value; /* Firefox */
  -ms-user-select: $value; /* Internet Explorer/Edge */
  user-select: $value; /* Chrome and Opera */
}
