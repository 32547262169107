#page-content {
  z-index: $contentLayerZIndex;
}

#header {
  z-index: 6;
}

#sidebar-container {
  z-index: 7;
}

.overlay {
  z-index: 101;
}

#alerts {
  z-index: 101;
}

#notices {
  z-index: 30;
}

#toasts {
  z-index: 300;
}

.select .options {
  z-index: 1000;
}
