@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); /* Firefox 16+*/
  }
  100% {
    -moz-transform: rotate(360deg); /* Firefox 16+*/
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

$borderSize: 5px;
$colors: #3a3a3a, #3a3a3a, #3a3a3a;

#preloader.preloader {
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  z-index: 1002;
  opacity: 1 !important;

  .image {
    background-image: url('/assets/img/icons/c6-logo/c6_logo_black.svg');
    background-size: cover;
    height: 60px;
    width: 60px;
    position: fixed;
  }

  .loader {
    background-image: url('/assets/img/icons/v2/loader.png');
    width: 200px;
    height: 200px;
    background-size: contain;
    animation: spin 2s linear infinite;
    border: none;
    &::before, &::after{
      display: none;
    }
  }
}
