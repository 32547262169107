*,
::before,
::after {
  box-sizing: border-box;
}

html, input {
  line-height: 1;
}

body {
  margin: 0;
}

a, a:link, a:visited, a:hover {
  color: inherit;
  text-decoration: none;
}

ul, ol {
  padding: 0;
  margin: 0;
}

ul:not(.list-styles), ol:not(.list-styles) {
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

p {
  margin: 0;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

svg {
  display: inline-block;
}

address {
  font-style: inherit;
}

fieldset {
  margin: 0;
  border: none;
  padding: 0;
}
