html {
  font-size: $baseScaling;
}
@media (max-width: $tightDesktop)
{
	html
	{
		font-size: $baseScaling * $tightDesktopScaling;
	}
}

@media (max-width: $phone)
{
	html
	{
		font-size: $baseScaling * $phoneScaling;
	}
}
