// Common Layout

@use "sass:math";

html {
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #f8f9fd;

  &.showing-overlay,
  &.show-sidebar {
    overflow-y: hidden;
  }
}

body {
  min-height: 100%;
}

@media (max-width: 846px) {
  #launcher {
    display: none !important;
  }
}

.pt-1 {
  padding-top: 1em;
}

.seller-tools-ticker-visible .sidebar-wrapper {
  height: calc(100vh - 2.6em) !important;
}

#wrapper {
  position: relative;

  body.modal-opened & {
    height: 100vh;
    overflow: hidden;
  }

  &:not(.no-sidebar-page) {
    margin-left: $sidebarWidth * 1px;
    padding-top: math.div($headerHeight * 1em, 16);

    #page-content {
      background: #f8f9fd;
      padding-top: math.div(38em, 16);
      padding-bottom: math.div(80em, 16);
      min-height: calc(100vh - #{$headerHeight * 1em} / 16);

      .st-review-request & {
        background: #FFF;
      }

      > .content {
        padding-left: math.div($contentPadding * 1em, 16);
        padding-right: math.div($contentPadding * 1em, 16);
      }
    }

    @media (max-width: $tablet) {
      margin-left: 0;
    }

    @media (max-width: $phone) {
      padding-top: math.div($headerHeightPhone * 1em, 16);

      #page-content {
        > .content {
          padding-left: math.div($phoneContentPadding * 1em, 16);
          padding-right: math.div($phoneContentPadding * 1em, 16);
        }
      }
    }
  }
}

#new-wrapper {
  position: relative;

  body.modal-opened & {
    height: 100vh;
    overflow: hidden;
  }

  st-customer-list {
    min-height: 99.9vh;
  }

  &:not(.no-sidebar-page) {
    margin-left: $newExpandedSidebarWidth * 1px;
    transition: all 400ms cubic-bezier(0.25, 0.8, 0.25, 1);

    &.collapsed {
      margin-left: $newSidebarWidth * 1px;
      transition: all 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    #page-content {
      background: #f8f9fd;
      padding-top: math.div(38em, 16);
      padding-bottom: math.div(80em, 16);
      min-height: calc(100vh - var(--c6-header-height));

      .st-review-request & {
        background: #FFF;
      }

      > .content {
        padding-left: math.div($contentPadding * 1em, 16);
        padding-right: math.div($contentPadding * 1em, 16);
      }
    }

    @media (max-width: $tablet) {
      margin-left: $newSidebarWidth * 1px;
    }

    @media (max-width: $phone) {

      #page-content {
        > .content {
          padding-left: math.div($phoneContentPadding * 1em, 16);
          padding-right: math.div($phoneContentPadding * 1em, 16);
        }
      }
    }
  }
}

// Layout helpers

.split-layout {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .left {
    position: absolute;
    left: 0;
    right: 50%;
    top: 0;
    bottom: 0;
  }

  .right {
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.wide-centered-content {
  max-width: math.div($wideCenteredContentWidth * 1em, 16);
  margin-left: auto;
  margin-right: auto;
  padding-left: math.div($contentPadding * 1em, 16);
  padding-right: math.div($contentPadding * 1em, 16);
}

// Flex layout helpers

.horizontal-layout,
.vertical-layout {
  display: flex;
  // align-items: stretch;
  > * {
    flex-grow: 1;
  }
}

.vertical-layout {
  flex-direction: column;
}

.xs-vertical-layout {
  @media (max-width: $phone) {
    flex-direction: column;
  }
}

.white-boxes-layout {
  &.horizontal-layout,
  .horizontal-layout {
    > * {
      &:not(:last-child) {
        margin-right: math.div(3em, 16);
      }
    }
  }

  &.vertical-layout,
  .vertical-layout {
    > * {
      &:not(:last-child) {
        margin-bottom: math.div(3em, 16);
      }
    }
  }

  .box {
    padding: math.div($whiteBoxPadding * 1em, 16);
    border-radius: math.div(5em, 16);
    background: white;
    @include whitePaneShadow;
    position: relative;

    @media (max-width: $phone) {
      padding: math.div($phoneWhiteBoxPadding * 1em, 16);
    }

    .box-title {
      font-size: math.div(18em, 16);
      font-weight: $GeomanistBook;
      color: $blackText;
      padding-bottom: math.div(24em, 22);
      small {
        display: block;
        margin-top: 0.3em;
      }
    }

    .text {
      line-height: math.div(25em, 16);
      word-break: break-all;

      p {
        margin-bottom: $paragraphMarginBottom;
      }
    }

    color: $darkGreyText;

    &>.loading-bar {
      overflow: hidden;
      width: 100%;
      position: absolute;
      right: 0;
      top: 0;
      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        width: 102%;
        background-color: $washedRed; /* use px color $primary-default */
        animation: LoadingBar 1.5s cubic-bezier(.5, .01, .51, 1) infinite;
      }
    }

  }
}

#sidebar-container {
  @media (max-width: $tablet) {
    body:not(.show-sidebar) & {
      &::before {
        opacity: 0;
        visibility: hidden;
        transition: opacity .25s $coolEasing, visibility 0s .25s;
      }

      #sidebar {
        transform: translateX(-100%);
      }
    }

    body.show-sidebar & {
      &::before {
        opacity: 1;
        visibility: inherit;
        transition: opacity .25s $coolEasing;
      }
    }
  }
}

@keyframes LoadingBar {
  0% {
    transform: translateX(-102%)
  }
  45% {
    transform: translateX(0)
  }
  55% {
    transform: translateX(0)
  }
  90% {
    transform: translateX(102%)
  }
  100% {
    transform: translateX(102%)
  }
}

@keyframes dot-keyframes {
  0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
}

.loading-dots {
  text-align: center;
  width: 100%;

  &--dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: #afafaf;
    border-radius: 10px;
    display: inline-block;
    height: 3px;
    width: 3px;

    &:nth-child(2) {
      animation-delay: .5s;
    }

    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}

.loading-circles,
.loading-circles::before,
.loading-circles::after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  border: 1px solid rgba(0, 129, 219, 0.1);
  border-left-color: rgba(0, 129, 219, 0.4);
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  border-radius: 999px;
}

.loading-circles {
  margin: -25px 0 0 -25px;
  height: 50px;
  width: 50px;
  -webkit-animation: animation-rotate 1000ms linear infinite;
  -moz-animation: animation-rotate 1000ms linear infinite;
  -o-animation: animation-rotate 1000ms linear infinite;
  animation: animation-rotate 1000ms linear infinite;
}

.loading-circles::before {
  content: "";
  margin: -22px 0 0 -22px;
  height: 44px;
  width: 44px;
  -webkit-animation: animation-rotate 1000ms linear infinite;
  -moz-animation: animation-rotate 1000ms linear infinite;
  -o-animation: animation-rotate 1000ms linear infinite;
  animation: animation-rotate 1000ms linear infinite;
}

.loading-circles::after {
  content: "";
  margin: -28px 0 0 -28px;
  height: 56px;
  width: 56px;
  -webkit-animation: animation-rotate 2000ms linear infinite;
  -moz-animation: animation-rotate 2000ms linear infinite;
  -o-animation: animation-rotate 2000ms linear infinite;
  animation: animation-rotate 2000ms linear infinite;
}

@-webkit-keyframes animation-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes animation-rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes animation-rotate {
  100% {
    -o-transform: rotate(360deg);
  }
}

@keyframes animation-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.ngxp__container {
  background: transparentize(#212529, .1);
  box-shadow: none !important;
  color: #FFF;
  font-size: .75em;
  word-wrap: break-word;
  line-height: 1.5em;
  max-width: math.div(300em, 12);
  min-width: math.div(300em, 15);
  z-index: 1000;
  text-align: center;

  & > .ngxp__arrow {
    width: 0 !important;
    height: 0 !important;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid transparentize(#212529, .1);
    top: -5px !important;

    &::before {
      display: none;
    }
  }
}

cdk-virtual-scroll-viewport {
  min-height: 50vh;
}

.cdk-virtual-scroll-viewport--empty {
  min-height: 0;
}
