// BUTTONS

// Unstyle normal buttons

@use "sass:math";

button {
  background: transparent;
  border: none;

  &:focus {
    outline: 0;
  }

  .button-content {
    display: inline-block;
    height: 100%;
  }
}

/*
  Show button with attention
   */
.attention::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  //z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 50px transparentize($midBlue, 0.3);
  animation: pulse 3s infinite;
}

/*
  Pulse animation for attention
   */
@keyframes pulse {
  0% {opacity: 0.4;}
  30% {opacity: 1;}
  100% {opacity: 0.4;}
}

// Styles for any elements that present as buttons,
// whether actual buttons or not

$nonInterfaceButtonSidePadding: 50; //px
$interfaceButtonSidePadding: 18; //px
$interfaceButtonIconWidth: 23; //px
$interfaceButtonMarginAfterIcon: 10; //px
$selectArrowWidth: 11; //px
$selectArrowHeight: 6; //px
$interfaceSelectMarginBeforeArrow: 20; //px
$nonInterfaceSelectMarginBeforeArrow: 30; //px

@mixin buttonRedHover {
  &:not(.no-hover):hover,
  &:focus {
    background: $washedRed !important;
    color: $whiteText !important;
  }
}

.button {
  display: inline-block;
  font-size: math.div(14em, 16);
  border-radius: math.div(4em, 14);
  text-align: center;
  cursor: pointer;
  position: relative;

  &.disabled {
    pointer-events: none;
  }

  &.interface-component {
    background: transparent;
    white-space: nowrap;
    font-weight: $GeomanistBook;
    color: $componentGreyText;
    border: math.div(1em, 14) solid $greyOutline;
    padding: math.div(12em, 14) math.div($interfaceButtonSidePadding * 1em, 14);

    &.no-border {
      border: 0!important;
    }

    &.grey-bg {
      background: $searchFieldBackground;;
    }

    &.blue-bg {
      background: $blue;
      color: #FFF;
      border-color: $blue;

      &:disabled {
        border-color: $blue;
      }

      &:not([disabled]):not(.disabled) {
        &.active,
        &:not(.no-hover):hover {
          background: darken( $blue, 10% );
          border-color: darken( $blue, 10% );
          color: white;
        }
      }
    }

    &.white-bg {
      background: white;
    }

    &.red-bg {
      background: #d65555;
      border: none;
      color: white;

      &.icon {
        color: white;
      }

      &:not([disabled]):not(.disabled) {
        &.active,
        &:not(.no-hover):hover {
          background: #a34949;
          color: white;
        }
      }
    }


    &.short {
      padding-top: math.div(8em, 14);
      padding-bottom: math.div(8em, 14);
    }

    &.icon {
      > .icon {
        position: absolute;
        white-space: nowrap;
        top: 0;
        bottom: 0;
        left: math.div($interfaceButtonSidePadding * 1em, 14);
        width: math.div($interfaceButtonIconWidth * 1em, 14);
        text-align: center;

        &::after {
          content: '';
          height: 100%;
        }

        svg, i,
        &::after {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    &[disabled],
    &.disabled {
      opacity: .5;
      border-color: $disabledOutline;
    }

    &:focus {
      border-color: $washedRed;
    }

    &:not([disabled]):not(.disabled) {
      &.active,
      &:not(.no-hover):hover {
        background: white;
        border-color: white;
        color: $washedRed;
        box-shadow: 0 math.div(10em, 14) math.div(20em, 14) rgba(0, 0, 0, .1);

        .icon {
          path {
            fill: $washedRed !important;
          }
        }
      }
    }
  }

  &:not(.interface-component) {
    text-transform: uppercase;
    font-weight: $GeomanistBook;
    letter-spacing: .2em;
    background: pink;
    border: none;
    color: $whiteText;
    padding: math.div(28em, 14) math.div($nonInterfaceButtonSidePadding * 1em, 14);

    &.short {
      font-size: math.div(12em, 16);
      padding: 1.3333em 0;
    }

    &.black {
      background: $blackText;
    }

    &.white {
      background: $whiteText;
      color: $blackText;
    }

    &.light-grey {
      background: $bgGrey;
      color: $blackText;
    }

    &.grey {
      background: $darkGreyText;
    }

    &.blue {
      background: $blue;
      @include buttonRedHover;
    }

    &.red {
      background: $washedRed;
    }

    &.red-hover {
      @include buttonRedHover;
    }

    &.outline,
    &.thin-outline {
      background: transparent;
      color: $componentGreyText;

      border: math.div(2em, 14) solid pink;

      &.outline {
        padding: calc(2em - (2em / 14)) calc(3.6em - (2em / 14));
      }

      &.thin-outline {
        border-width: math.div(1em, 14);
        padding: calc(2em - (1em / 14)) calc(3.6em - (1em / 14));
      }

      &.short {
        border: math.div(2em, 12) solid pink;

        &.outline {
          padding: calc(1.3333em - (2em / 12)) 0;
        }

        &.thin-outline {
          padding: calc(1.3333em - (1em / 12)) 0;
        }
      }

      &.black {
        color: $blackText;
        border-color: $blackText;

        &:not(.no-hover):hover,
        &:focus {
          background: $blue !important;
          border-color: $blue !important;
          color: $whiteText !important;
        }
      }

      &.white {
        color: $whiteText;
        border-color: $whiteText;

        &:not(.no-hover):hover,
        &:focus {
          background: $blue !important;
          border-color: $blue !important;
          color: $whiteText !important;
        }

        &.white-hover {
          &:not(.no-hover):hover,
          &:focus {
            background: $whiteText !important;
            border-color: $whiteText !important;
            color: $blue !important;
          }
        }
      }

      &.blue {
        color: $blue;
        border-color: $blue;

        &:not(.no-hover):hover,
        &:focus {
          background: $washedRed !important;
          border-color: $washedRed !important;
          color: $whiteText !important;
        }
      }

      &.red {
        color: $washedRed;
        border-color: $washedRed;

        &:not(.no-hover):hover,
        &:focus {
          background: $blue !important;
          border-color: $blue !important;
          color: $whiteText !important;
        }
      }

      &.grey {
        color: $darkGreyText;
        border-color: $darkGreyText;

        &:not(.no-hover):hover,
        &:focus {
          background: $blue !important;
          border-color: $blue !important;
          color: $whiteText !important;
        }
      }
    }

    &:not(.no-hover):hover,
    &:focus {
      background: $blue !important;
      color: $whiteText !important;
    }
  }
}

// Button groups

.button-group {
  display: flex;

  &.anchor-right {
    justify-content: flex-end;
  }

  .button,
  .select,
  st-select {
    &.interface-component {
      &:not(:first-child) {
        margin-left: math.div(9em, 12);
      }
    }
  }

  // fix for nested selects
  .nested-select .options .interface-component {
    margin-left: math.div(9em, 12);
  }

  &.joined {
    .button {
      display: block;

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.interface-component {
        &:not(:first-child) {
          margin-left: math.div(-1em, 12);
        }
      }

      &:not(.interface-component) {
        &.outline {
          &:not(:first-child) {
            margin-left: math.div(-2em, 12);
          }
        }

        &.thin-outline {
          &:not(:first-child) {
            margin-left: math.div(-1em, 12);
          }
        }
      }
    }
  }
}

// Selects

.select {
  position: relative;

  .button {
    text-align: left;
    letter-spacing: 0;
  }

  button {
    width: 100%;

    &:not(:hover) .placeholder {
      color: inherit;
      opacity: .25;
    }
  }

  &:not(.interface-component) {
    .button {
      @include buttonRedHover;
    }

    > .button {
      padding-right: math.div((30 + $nonInterfaceSelectMarginBeforeArrow + $selectArrowWidth) * 1em, 14);

      &::after {
        content: '';
        @include triangleDown(math.div($selectArrowWidth * 1em, 14), math.div($selectArrowHeight * 1em, 14), $downdownArrow);
        position: absolute;
        right: math.div(30 * 1em, 14);
        top: 50%;
        margin-top: math.div(-$selectArrowHeight * 0.5 * 1em, 14);
      }
    }
  }

  &.interface-component {
    > .button {
      padding-right: math.div(($interfaceButtonSidePadding + $interfaceSelectMarginBeforeArrow + $selectArrowWidth) * 1em, 14);

      &::after {
        content: '';
        @include triangleDown(math.div($selectArrowWidth * 1em, 14), math.div($selectArrowHeight * 1em, 14), $downdownArrow);
        position: absolute;
        right: math.div($interfaceButtonSidePadding * 1em, 14);
        top: 50%;
        margin-top: math.div(-$selectArrowHeight * 0.5 * 1em, 14);
      }

      &:not([disabled]) {
        &.active,
        &:not(.no-hover):hover {
          &::after {
            border-top-color: $washedRed;
          }
        }
      }
    }

    .options {
      box-shadow: 0 math.div(10em, 12) math.div(20em, 12) rgba(0, 0, 0, .1);
    }
  }

  .options {
    position: absolute;
    left: 0;
    top: calc(100% + (4em / 16));
    display: flex;
    flex-direction: column;
    background: $bgGrey;
    min-width: 100%;
    border-radius: math.div(4em, 16);
    border: math.div(1em, 16) solid $greyOutline;

    &.anchor-right {
      left: auto;
      right: 0;
    }

    &.anchor-bottom {
      top: auto;
      bottom: calc(100% + (4em / 16));
    }

    .transformer {
      min-width: 100%;
      transition: transform .25s $coolEasing;

      &.left-behind {
        transform: translateX(-100%);
      }

      li {
        min-width: 100%;

        > * {
          min-width: 100%;
        }

        > .select > button,
        > button,
        > .button,
        > st-select > .select > button {
          min-width: 100%;
          border-left: none;
          border-right: none;
          border-top: none;
          border-radius: 0;
          text-align: left;
          position: relative;

          &:hover,
          &:focus {
            z-index: 2;

            &.interface-component {
              border-color: $greyOutline !important;
              box-shadow: none !important;
            }
          }
        }

        > .select,
        > st-select > .select {
          > button {
            &::after {
              transform: rotate(-90deg);
            }
          }
        }

        > .select,
        > st-select > .select {
          > .options {
            position: absolute;
            left: $secondLevelSelectOffset;
            top: 0;
            border-radius: 0;
            border: none;
          }

          &:hover {
            > .options {
              display: block;
            }
          }
        }
      }
    }

    @media (max-width: $phone) {
      overflow: hidden;
      transition: width .25s $coolEasing, height .25s $coolEasing;

      .transformer {
        li {
          > .select {
            > .options {
              left: $secondLevelSelectOffset;
              top: math.div(-1em, 16);
              border-radius: math.div(4em, 16);
              border: math.div(1em, 16) solid $greyOutline;
            }
          }
        }
      }
    }
  }

  &.icons {
    .options {
      .transformer {
        li {
          > button,
          > .button {
            padding-left: math.div(52em, 14);
          }
        }
      }
    }
  }

  &:not(.opened) .options {
    display: none;
  }
}

// If an interface buton should have an icon placeholder.
// Either by its own characteristics or by being in a
// list with icons.

.button.interface-component.icon,
.select .options.icons .transformer .button.interface-component {
  padding-left: math.div(($interfaceButtonSidePadding + $interfaceButtonIconWidth + $interfaceButtonMarginAfterIcon) * 1em, 14);
}

// Checkboxes

.checkbox {
  $size: 20; // px

  width: math.div($size * 1em, 16) + math.div($checkboxOverflow * 1em, 16) * 2;
  height: math.div($size * 1em, 16) + math.div($checkboxOverflow * 1em, 16) * 2;
  border-radius: math.div(2em, 16) + math.div($checkboxOverflow * 1em, 16);
  border: math.div($checkboxOverflow * 1em, 16) solid transparent;
  box-shadow: inset 0 0 0 math.div(1em, 16) $checkboxBorder;
  transition: box-shadow .15s $coolEasing;
  cursor: pointer;

  position: relative;

  input {
    display: none;
  }

  &:focus,
  &:active {
    outline: 0;
    border: math.div($checkboxOverflow * 1em, 16) solid transparent;
  }

  $transform: translateX(math.div(3.5em, 16)) translateY(math.div(-3em, 16)) rotate(-45deg);

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: math.div(14em, 16);
    transform: $transform;
    transform-origin: 0 0;
  }

  &::before {
    width: math.div(2em, 16);
    height: 0;
    border-left: math.div(2em, 16) solid white;
    transition: height 0s $coolEasing .15s;
  }

  &::after {
    width: 0;
    height: math.div(6em, 16);
    border-bottom: math.div(2em, 16) solid white;
    transition: width 0s $coolEasing .15s;
  }

  &.checked {
    box-shadow: inset 0 0 math.div(5em, 16) math.div($size * 1em, 16) * 0.5 $washedRed;

    &::before {
      height: math.div(6em, 16);
      transition: height .2s $coolEasing .05s;
    }

    &::after {
      width: math.div(12em, 16);
      transition: width .2s $coolEasing .05s + .1s;
    }
  }
}

.checkbox.button-checkbox {
  display: inline-block;
  vertical-align: middle;
  margin-left: math.div(-$checkboxOverflow * 1em, 16);
  margin-top: math.div(-$checkboxOverflow * 1em, 16);
  margin-bottom: math.div(-$checkboxOverflow * 1em, 16);
}

// Fields

input:focus {
  border: 1px solid $washedRed;
  outline: 0;
}

textarea {
  resize: none;
  overflow: auto;
  padding: math.div(16em, 16) !important;
  line-height: math.div(18em, 16);
}

.block {
  display: flex;
  align-items: flex-start;

  > * {
    &:not(:last-child) {
      margin-right: math.div(20em, 16);
    }
  }
}

.field-container {
  margin-bottom: math.div(20em, 16);
  position: relative;
}

.field {
  display: block;
  width: 100%;

  font-size: math.div(14em, 16);
  border-radius: math.div(4em, 14);
  border: math.div(1em, 14) solid white;

  &:disabled {
    cursor: not-allowed;
  }
  &.form-underline {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    background-color: transparent!important;
  }
  &.interface-component {
    border-color: $greyOutline;
    padding: math.div(10em, 14) math.div(20em, 14);
    font-weight: $GeomanistBook;
    background: $searchFieldBackground;
    position: relative;

    &:focus, &:focus-within {
      border-color: $washedRed;
      outline: none !important;
      //border:1px solid red;
      //box-shadow: 0 0 10px #719ECE;
    }

    &::-webkit-input-placeholder {
      color: $componentGreyText;
    }

    &::-moz-placeholder {
      color: $componentGreyText;
    }

    &:-ms-input-placeholder {
      color: $componentGreyText;
    }

    &:-moz-placeholder {
      color: $componentGreyText;
    }
  }

  &:not(.interface-component) {
    height: math.div(70em, 14);
    padding: math.div(27em, 14) math.div($nonInterfaceButtonSidePadding * 1em, 14);
    background: white;
    text-transform: uppercase;
    font-weight: $GeomanistBook;
    box-shadow: 0 math.div(20em, 14) math.div(60em, 14) rgba(0, 0, 0, .05);

    &.short {
      height: math.div(54em, 14);
      padding-top: math.div(19em - 1em, 14);
      padding-bottom: math.div(19em - 1em, 14);
    }

    &:focus {
      border-color: $washedRed;
    }

    &::-webkit-input-placeholder {
      color: inherit;
      opacity: .25;
    }

    &::-moz-placeholder {
      color: inherit;
      opacity: .25;
    }

    &:-ms-input-placeholder {
      color: inherit;
      opacity: .25;
    }

    &:-moz-placeholder {
      color: inherit;
      opacity: .25;
    }

    &.error,
    .field-container.error & {
      background: #fff8f8;
      border: math.div(2em, 14) solid #ff1919;
      padding-top: math.div(26em, 14);
      padding-bottom: math.div(26em, 14);

      &.short {
        padding-top: math.div(18em, 14);
        padding-bottom: math.div(18em, 14);
      }
    }

    &.success,
    .field-container.success & {
      background: #fbfdf9;
      border: math.div(2em, 14) solid $green;
      padding-top: math.div(26em, 14);
      padding-bottom: math.div(26em, 14);

      &.short {
        padding-top: math.div(18em, 14);
        padding-bottom: math.div(18em, 14);
      }
    }
  }
}

.field-error {
  font-size: math.div(12em, 16);
  padding: 0 math.div(52em - 1em, 11);
  position: absolute;
  left: 0;
  right: 0;
  bottom: math.div(13em, 11);
  white-space: nowrap;
  color: #ff1919;
  display: none;

  .field-container.error & {
    display: block;
  }
}

.select-field {
  display: flex;
  align-items: flex-start;
  position: relative;
  box-shadow: 0 math.div(20em, 16) math.div(60em, 16) rgba(0, 0, 0, .05);
  margin-bottom: math.div(20em, 16);

  > .select {
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    z-index: 2;

    > .button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  > .field,
  > .field-container {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .field {
    box-shadow: none;
    margin-left: math.div(-1em, 14);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .field-container {
    margin-bottom: 0;
  }

  &.field-focus {
    > .select {
      > .button {
        background: $washedRed;
        color: white;
      }
    }
  }

  .options {
    &.search {
      background: white;

      .search-box {
        margin: math.div(10em, 16);

        button {
          width: auto;
        }
      }

      ol {
        border-top: 1px solid $greyOutline;
      }
    }
  }
}

.label {
  &.interface-component {
    display: block;
    // color: $blackText;
    font-weight: $GeomanistBook;
    padding-bottom: math.div(10em, 16);
  }
}

.button-with-error-msg {
  display: flex;
  justify-content: flex-end;
  &.center{
    display: inline-flex;
  }
  &.vertical{
    flex-direction: column;
    st-button {
      margin-top: 1em;
    }
  }
  // button {
  //   color: white !important;
  // }
}

.page-form .button-with-error-msg button {
  color: white !important;
}

.sign-in-field {
  height: 40px;
  padding: 0 20px 0 44px;
  display: flex;
  align-items: center;
  font-family: 'Manrope', sans-serif;
  border-radius: 40px;
  border: 1px solid #C4C4C4;
  width: 100%;

  &::placeholder {
    color: var(--gray-21);
  }
}
